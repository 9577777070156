import React from 'react'

import ReferralBanner from 'components/banner/referral/ReferralBanner'

import './banner-section.scss'

function BannerSection({ location }) {
  return (
    <section className="x__wallet__banner">
      <ReferralBanner location={location} />
    </section>
  )
}

export default BannerSection
