import React from 'react'

import useDetectUserAgent from 'js/utils/hooks/use-detect-user-agent'
import useIsMounted from 'js/utils/hooks/useIsMounted'
import { useLatestVersion } from 'js/utils/hooks/useLatestVersion'

import Pill from './parts/pill'
import buildDownloadLinks from './utils/build-download-links'
import buildDownloadItems from './utils/build-download-items'
import getToggleItem from './utils/get-toggle-item'

const storeMap = {
  'play-store': 'android',
  'app-store': 'ios',
}

function DownloadPill({
  location,
  pathname,
  os,
  store,
  trackerPage,
  androidLinkProps = {},
  extensionLinkProps = {},
}) {
  const isMounted = useIsMounted()
  const releaseVersion = useLatestVersion().latest
  const { loading, os: userAgentOs } = useDetectUserAgent()

  const osWithFallback = storeMap[store] || os || userAgentOs

  const downloadLinks = buildDownloadLinks({
    variant: osWithFallback,
    location: isMounted && location,
    referrer: isMounted && document?.referrer,
    version: releaseVersion,
    androidLinkProps,
    extensionLinkProps,
  })
  const items = buildDownloadItems(downloadLinks, trackerPage, pathname)
  const toggleItem = getToggleItem({
    items,
    os: osWithFallback,
    storeIcon: !!storeMap[store] && store,
    condensedLabel: true,
  })

  return !loading && <Pill {...toggleItem} />
}

export default DownloadPill
