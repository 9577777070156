import React from 'react'

import WatchYouTubeVideoButton from 'components/button/watch-youtube-video'
import HeroSection from 'components/pages/shared/hero-section'
import useDimensions from 'js/utils/hooks/useDimensions'

import MobilePreview from './parts/mobile-preview'

function HeaderSection({ location, pageName, data }) {
  const { backgroundImage, previewMedia, videoId } = data

  const { isMobile } = useDimensions()

  return (
    <HeroSection
      location={location}
      pageName={pageName}
      data={data}
      backgroundImage={backgroundImage}
      downloadButtonProps={{ forceShowDownloadPageButton: !isMobile }}
      limitBackgroundMobileHeight
    >
      {!!previewMedia.images && <MobilePreview images={previewMedia.images} />}
      {!!videoId && <WatchYouTubeVideoButton videoId={videoId} />}
    </HeroSection>
  )
}

export default HeaderSection
