import classNames from 'classnames'
import React from 'react'

import Link from 'components/link'
import { DOWNLOAD_PAGE_PATH } from 'src/constants'

import Rating from './rating'

import './pill.scss'

function Pill({ className, label, link, target = '_blank', onClick, itemKey, Icon }) {
  const isExternal = link?.startsWith('https://')
  const isMobileItem = itemKey === 'ios' || itemKey === 'android'

  const wrapperProps = {
    className: classNames('exodus__download__pill', className),
    href: isExternal ? link : link || DOWNLOAD_PAGE_PATH,
    target,
    onClick,
  }

  return (
    <Link {...wrapperProps}>
      <i>{Icon}</i>
      <span>
        <span>{label}</span>
        {isMobileItem && <Rating itemKey={itemKey} size="md" />}
      </span>
    </Link>
  )
}

export default Pill
