import React, { useEffect } from 'react'

import './style.scss'

function PlayIcon() {
  return (
    <svg viewBox="0 0 9 10" fill="currentColor">
      <path d="M8.1 4.48038C8.5 4.71132 8.5 5.28867 8.1 5.51961L0.900001 9.67654C0.500001 9.90748 4.89512e-07 9.6188 5.09701e-07 9.15692L8.73111e-07 0.843078C8.933e-07 0.381197 0.500001 0.0925221 0.900001 0.323462L8.1 4.48038Z" />
    </svg>
  )
}

function WatchYouTubeVideoButton({
  videoId,
  videoUrl = '',
  autoplay = 1,
  fullscreen = 1,
  buttonText = 'Watch Video',
}) {
  const videoParams = new URLSearchParams({
    autoplay: autoplay.toString(),
    fs: fullscreen.toString(),
  })

  // If the video URL is provided, just extract the video ID.
  if (videoUrl) {
    const url = new URL(videoUrl)
    videoId = url.searchParams.get('v')
  }

  const videoSrc = `https://www.youtube.com/embed/${videoId}?${videoParams.toString()}`

  // Prevent the default behavior of the anchor tag.
  const handleClick = (event) => {
    event.preventDefault()
  }

  // Load the FSLightbox plugin when the component mounts.
  useEffect(() => {
    if (typeof window !== 'undefined') {
      import('src/plugins/fslightbox.js')
    }
  }, [])

  return (
    <a href={videoSrc} className="x__button-watch-video" onClick={handleClick} data-fslightbox>
      <PlayIcon />
      <span>{buttonText}</span>
    </a>
  )
}

export default WatchYouTubeVideoButton
