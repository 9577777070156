import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import useDimensions from 'js/utils/hooks/useDimensions'

import Carousel from './carousel'

import './mobile-preview.scss'

function MobilePreview({ images }) {
  const { images: items, gradient } = images

  const { isMobile } = useDimensions()

  if (!items?.length) return null

  return (
    <div className="x__mobile-page__preview">
      {!!gradient && !isMobile && (
        <GatsbyImage
          className="x__mobile-page__preview__gradient"
          image={gradient}
          alt="Gradient blur"
          loading="lazy"
        />
      )}
      <div className="x__mobile-page__preview__content">
        {isMobile ? (
          <div className="x__mobile-page__preview__carousel">
            <Carousel
              pause={!isMobile}
              items={items}
              itemWrapperClassName="x__mobile-page__preview__image"
              ItemComponent={({ item, key }) => (
                <GatsbyImage
                  image={item}
                  alt={`Mobile preview ${(key % items.length) + 1}`}
                  loading={key === 0 ? 'eager' : 'lazy'}
                />
              )}
            />
          </div>
        ) : (
          items.map((image, index) => (
            <div key={index} className="x__mobile-page__preview__image">
              <GatsbyImage image={image} alt={`Mobile preview ${index + 1}`} loading="eager" />
            </div>
          ))
        )}
      </div>
    </div>
  )
}

export default MobilePreview
