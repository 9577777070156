// dependencies.
import React from 'react'

// Styles & Images
import 'src/components/banner/referral/scss/ReferralBanner.scss'

const queryUrlParams = (location) => {
  if (!location) return { referralSource: null, code: null }

  const params = new URLSearchParams(location.search)
  const referralSource = params.get('referral_source')
  const code = params.get('code')?.toUpperCase()

  return { referralSource, code }
}

const buildDataObject = (location) => {
  const { referralSource, code } = queryUrlParams(location)

  const title = 'Swap & earn crypto when you or your friends swap'
  let description =
    'Use the code you’ve been given to earn and enjoy reduced fees on swaps up to $2500, plus a reward of up to $50 in USDC.'

  if (!referralSource) {
    return { title, description }
  }

  const descriptionWithCode = (
    <>
      Use code{' '}
      <span className="x__referral-banner__description__code">
        {referralSource === 'exodusreward' ? 'REWARD' : code}
      </span>{' '}
      to earn and enjoy reduced fees on swaps up to $2500, plus a reward of up to $50 in USDC.
    </>
  )

  if (
    referralSource === 'exodusreward' ||
    (referralSource === 'influencerreferrer' && !!code && code !== 'REWARD') ||
    (referralSource === 'p2preferrer' && !!code && code !== 'REWARD')
  ) {
    description = descriptionWithCode
  }

  return { title, description }
}

const ReferralBanner = ({ location }) => {
  const { title, description } = buildDataObject(location)

  return (
    <div className="x__referral-banner">
      <h3 className="x__referral-banner__title">{title}</h3>
      <p className="x__referral-banner__description">{description}</p>
    </div>
  )
}

export default ReferralBanner
